import {useEffect, useState} from 'react';
import {Box, Button, Flex, Slide, Spacer, TableContainer, Text, useDisclosure, Wrap, WrapItem,} from '@chakra-ui/react';
import {DownloadIcon} from '@chakra-ui/icons';

import {Structures} from './ColumnsTable';
import {observer} from 'mobx-react-lite';
import {Data} from '../stores/data';
import ReportDetails from './ReportDetails';
import {Search} from './Search';
import {useTranslation} from 'react-i18next';
import WATERMark from '../assets/watermark.png';
import ReportDownloadFilter from './ReportDownloadFilter';
import {DownloadCategoriesEnum, DownloadTypeEnum} from '@hmf/portal-api-client';
import TableComponent from './Table';

const ReportPage = observer(
  ({dataStore, height}: { dataStore: Data; height: any }) => {
    const [t] = useTranslation();
    const [checkedSamples, setCheckedSamples] = useState<number[]>([]);
    const {isOpen, onClose, onToggle} = useDisclosure();
    const {
      isOpen: downloadIsOpen,
      onClose: downloadOnClose,
      onToggle: downloadOnToggle,
    } = useDisclosure();
    const [useData, setData] = useState(dataStore.analyses);
    const [filter, setFilter] =
      useState<{ column: string; value: boolean | undefined; }>({column: 'processed', value: undefined});
    const [globalFilter, setGlobalFilter] = useState<unknown>();

    useEffect(() => {
      dataStore.fetchAnalyses();

      if (dataStore.selectedAnalysis) {
        dataStore.fetchReportComponents(dataStore.selectedAnalysis.reportId);
      }
    }, []);

    useEffect(() => {
      setData(dataStore.analyses);
    }, [dataStore.analyses]);

    const showDrawer = (row) => {
      if (!isOpen) {
        onToggle();
      }

      dataStore.setSelectedAnalysis(dataStore.analyses[row.id].reportId);
    };

    const downloadReports = (categories: Set<DownloadCategoriesEnum>) => {
      const reportsToDownload = checkedSamples.map(
        (cs) => dataStore.analyses[cs].reportId
      );
      dataStore.download(reportsToDownload, DownloadTypeEnum.Report, categories).then((_) => {
        downloadOnToggle();
        dataStore.fetchAnalyses();

        if (dataStore.selectedAnalysis) {
          dataStore.fetchReportComponents(dataStore.selectedAnalysis.reportId);
        }
      });
    };
    return (
      <>
        <Flex
          direction="row"
          height={height}
          backgroundImage={WATERMark}
          backgroundRepeat="no-repeat"
          backgroundPosition="90%"
          backgroundAttachment="inherit"
          backgroundSize="65%"
          style={{backgroundPositionY: 'bottom'}}
        >
          <Flex direction="column" flex={1} bgColor="#D4D9E7">
            <Box
              marginTop="15"
              marginRight="20"
              marginLeft="20"
              marginBottom="25"
              border="1px"
              borderColor="#A0AEBE"
              shadow="lg"
              backgroundColor="white"
            >
              <Text
                marginTop="15"
                marginLeft="5"
                textTransform="uppercase"
                letterSpacing={0.5}
                color="#5E5873"
                fontSize="18"
              >
                {t('table.actions.reports.title')}
              </Text>
              <Wrap marginX={5} mb={18} mt={2}>
                <WrapItem h={12}>
                  <Button
                    color={filter?.column === 'processed' && filter.value === undefined ? 'white' : '#6E6B73'}
                    bg={filter?.column === 'processed' && filter.value === undefined ? '#304B99' : 'white'}
                    boxShadow="md"
                    variant="outline"
                    borderColor="gray.300"
                    fontSize={14}
                    h={10}
                    px={5}
                    onClick={() => {
                      setFilter({column: 'processed', value: undefined});
                      setCheckedSamples([]);
                    }}
                  >
                    {t('table.actions.reports.allSamples')}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    color={filter?.column === 'processed' && filter.value === false ? 'white' : '#6E6B73'}
                    bg={filter?.column === 'processed' && filter.value === false ? '#304B99' : 'white'}
                    borderColor="gray.300"
                    boxShadow="md"
                    variant="outline"
                    fontSize={14}
                    h={10}
                    px={5}
                    onClick={() => {
                      setFilter({column: 'processed', value: false});
                      setCheckedSamples([]);
                    }}
                  >
                    {t('table.actions.reports.newSamples')}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Search
                    filter={globalFilter}
                    onChange={(v) => {
                      setGlobalFilter(v);
                    }}
                  />
                </WrapItem>
                <WrapItem>
                  <Button
                    fontSize={14}
                    h={10}
                    backgroundColor="white"
                    boxShadow="md"
                    variant="outline"
                    borderColor="gray.300"
                    rightIcon={<DownloadIcon fontSize={20}/>}
                    onClick={() => downloadOnToggle()}
                    paddingX={5}
                    disabled={!checkedSamples.length}
                  >
                    {t('table.actions.reports.download')}
                  </Button>
                </WrapItem>
                <WrapItem>
                  <Button
                    fontSize={14}
                    h={10}
                    backgroundColor="white"
                    boxShadow="md"
                    variant="outline"
                    borderColor="gray.300"
                    onClick={() => setCheckedSamples([])}
                    paddingX={5}
                    disabled={!checkedSamples.length}
                  >
                    {t('table.actions.reports.cancel')}
                  </Button>
                </WrapItem>
                <Spacer/>
                  <WrapItem>
                      <a
                          href={process.env.PUBLIC_URL + "/files/instructionslides_results_module_dutch.pdf"}
                          download="Instructieslides - uitslagenmodule.pdf">
                          <Button
                              fontSize={14}
                              h={10}
                              backgroundColor="white"
                              boxShadow="md"
                              variant="outline"
                              borderColor="gray.300"
                              paddingX={5}
                          >
                              {t('table.actions.reports.help')}
                          </Button>
                      </a>
                  </WrapItem>
              </Wrap>

                <TableContainer
                 marginTop="15"
                 marginRight="18"
                 marginLeft="18"
                 marginBottom="25"
                >
                    <TableComponent
                        columns={{
                            structure: Structures.REPORTS,
                            texts: 'table.reports',
                        }}
                        data={useData}
                        hoverRow={true}
                        addFilter={filter}
                        addGlobalFilter={globalFilter}
                  onClickCheckboxHeader={(isChecked, page) =>
                    isChecked
                      ? setCheckedSamples([
                        ...checkedSamples,
                        ...page
                          .map((row) => row.index)
                          .filter((index) => !checkedSamples.includes(index))
                      ])
                      : setCheckedSamples([])
                  }
                  isCheckedCheckboxHeader={(page) =>
                    page.every((r) => checkedSamples.includes(r.index))
                  }
                  onClickCheckboxBody={(isChecked, id) =>
                    isChecked
                      ? setCheckedSamples([...checkedSamples, id])
                      : setCheckedSamples([
                        ...checkedSamples.filter((i) => i !== id),
                      ])
                  }
                  isCheckedCheckboxBody={(id) => checkedSamples.includes(id)}
                  onClickIcon={(row) => showDrawer(row)}
                  colorSelectedRow={true}
                  selectedRowId={dataStore.selectedAnalysis?.identifier}
                />
              </TableContainer>
            </Box>
          </Flex>

          {isOpen && (
            <Box flex={1 / 2} shadow="lg">
              <Slide
                direction="right"
                in={isOpen}
                style={{position: 'relative', height: '100%'}}
              >
                <ReportDetails
                  dataStore={dataStore}
                  onCloseSlider={() => {
                    onClose();
                    dataStore.setSelectedAnalysis(-1);
                  }}
                />
              </Slide>
            </Box>
          )}
        </Flex>

        <ReportDownloadFilter
          isDownloading={dataStore.isDownloading}
          isOpen={downloadIsOpen}
          onClose={downloadOnClose}
          onAccept={downloadReports}
        />
      </>
    );
  }
);

export default ReportPage;
